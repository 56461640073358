var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "index",
    style: {
      backgroundImage: _vm.isStep == 0 && `url(${require(`@images/${_vm.theme}/bg2.jpg`)})` || _vm.isStep == 1 && `url(${require(`@images/${_vm.theme}/bg3.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "index-main"
  }, [_c('div', {
    staticClass: "tab"
  }, _vm._l(2, function (_, index) {
    return _c('div', {
      staticClass: "tab-item",
      class: _vm.isStep === index && 'active'
    });
  }), 0), _vm.isStep === 0 ? [_c('div', {
    staticClass: "title"
  }, [_c('div', {
    staticClass: "title_main"
  }, [_vm._v(_vm._s(_vm.$lang("Rising star!")))]), _c('div', {
    staticClass: "title_subtitle"
  }, [_vm._v(_vm._s(_vm.$lang("You’ve joined Telegram")))])]), _c('div', {
    staticClass: "ago"
  }, [_c('div', {
    staticClass: "ago-main",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/page2_bg2.png`)})`
    }
  }, [_c('div', {
    staticClass: "ago-con",
    class: !_vm.isMobile && 'pc'
  }, [_c('span', {
    style: {
      color: _vm.themeMainColor(_vm.theme).ago
    }
  }, [_vm._v(_vm._s(_vm.userData.age))]), _vm.userData.age <= 1 ? _c('i', [_vm._v(_vm._s(_vm.$lang("year ago")))]) : _c('i', [_vm._v(_vm._s(_vm.$lang("years ago")))])])])]), _c('div', {
    staticClass: "index-info"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Your account number is")) + " #" + _vm._s(_vm.userData.tg_id)), _c('br'), _vm._v(" " + _vm._s(_vm.$lang("You're in the Top")) + " " + _vm._s(_vm.userData.top_rinking || 0) + "% " + _vm._s(_vm.$lang("Telegram users")) + " ")]), _c('div', {
    staticClass: "index-btn",
    on: {
      "click": _vm.onContinueRis
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang("Continue")) + " ")])] : _vm.isStep === 1 ? [_c('div', {
    staticClass: "title"
  }, [_c('div', {
    staticClass: "title_main"
  }, [_vm._v(_vm._s(_vm.$lang("You are amazing!")))]), _c('div', {
    staticClass: "title_subtitle"
  }, [_vm._v(_vm._s(_vm.$lang(`Here is your ${_vm.setting.coin} reward`)))])]), _c('div', {
    staticClass: "index-car"
  }, [_c('div', {
    staticClass: "index-car-main",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/ris-logo.png`)})`
    }
  }), _c('div', {
    staticClass: "index-car-num",
    style: {
      color: _vm.themeMainColor(_vm.theme).ago,
      fontWeight: _vm.theme === 'car' ? 'bold' : 'normal'
    }
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.userData.age_reward + _vm.userData.premium_reward)))])]), _c('div', {
    staticClass: "index-info_s"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Thanks for your time on Telegram")) + " ")]), _c('div', {
    staticClass: "index-btn",
    on: {
      "click": _vm.onSkipGame
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang("Continue")) + " ")])] : _vm._e()], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };