var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mNav",
    style: {
      background: _vm.themeMainColor(_vm.theme).mNav,
      width: _vm.theme == 'car' ? '90%' : '100%',
      borderRadius: _vm.theme == 'car' ? '80px' : '0',
      borderRadius: _vm.theme == 'car' ? '80px' : '0',
      bottom: _vm.theme == 'car' ? '20px' : '0'
    }
  }, [_c('div', {
    staticClass: "mNav-main"
  }, _vm._l(_vm.navList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "mNav-item",
      class: item.path === _vm.selectNav && 'active',
      on: {
        "click": function ($event) {
          return _vm.onNav(item);
        }
      }
    }, [_c('div', {
      staticClass: "mNav-item__icon"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/icon-${item.path}.png`),
        "alt": ""
      }
    }), item.title == 'Tasks' && _vm.taskCount ? _c('span', [_vm._v(_vm._s(_vm.taskCount))]) : _vm._e()]), _c('div', {
      staticClass: "mNav-item__title"
    }, [_vm._v(_vm._s(item.title))])]);
  }), 0)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };