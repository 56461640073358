var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "check"
  }, [_c('div', {
    staticClass: "check-title"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Checking your"))), _c('br'), _vm._v(" " + _vm._s(_vm.$lang("account")) + " ")]), _c('div', {
    staticClass: "progress"
  }, [_c('div', {
    staticClass: "progress-item",
    style: {
      opacity: _vm.progressList[0].show ? 1 : 0
    }
  }, [_c('div', {
    staticClass: "progress__title"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Account Age Verified")) + " "), _c('div', {
    staticClass: "progress_title_icon",
    style: {
      color: _vm.progressList[0].isOk ? _vm.themeMainColor(_vm.theme).ok : '#fff'
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-fill-ok"
    }
  })], 1)]), _c('div', {
    staticClass: "progress_main"
  }, [_c('div', {
    staticClass: "bar",
    style: {
      width: _vm.progressList[0].progress + 'px'
    }
  })])]), _c('div', {
    staticClass: "progress-item",
    style: {
      opacity: _vm.progressList[1].show ? 1 : 0
    }
  }, [_c('div', {
    staticClass: "progress__title"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Activity Level Analyzed")) + " "), _c('div', {
    staticClass: "progress_title_icon",
    style: {
      color: _vm.progressList[1].isOk ? _vm.themeMainColor(_vm.theme).ok : '#fff'
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-fill-ok"
    }
  })], 1)]), _c('div', {
    staticClass: "progress_main"
  }, [_c('div', {
    staticClass: "bar",
    style: {
      width: _vm.progressList[1].progress + 'px'
    }
  })])]), _c('div', {
    staticClass: "progress-item",
    style: {
      opacity: _vm.progressList[2].show ? 1 : 0
    }
  }, [_c('div', {
    staticClass: "progress__title"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Telegram Preminum Checked")) + " "), _c('div', {
    staticClass: "progress_title_icon",
    style: {
      color: _vm.progressList[2].isOk ? _vm.themeMainColor(_vm.theme).ok : '#fff'
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-fill-ok"
    }
  })], 1)]), _c('div', {
    staticClass: "progress_main"
  }, [_c('div', {
    staticClass: "bar",
    style: {
      width: _vm.progressList[2].progress + 'px'
    }
  })])]), _c('div', {
    staticClass: "progress-item",
    style: {
      opacity: _vm.progressList[3].show ? 1 : 0
    }
  }, [_c('div', {
    staticClass: "progress__title"
  }, [_vm._v(" " + _vm._s(_vm.$lang("OG Status Confirmed")) + " "), _c('div', {
    staticClass: "progress_title_icon",
    style: {
      color: _vm.progressList[3].isOk ? _vm.themeMainColor(_vm.theme).ok : '#fff'
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-fill-ok"
    }
  })], 1)]), _c('div', {
    staticClass: "progress_main"
  }, [_c('div', {
    staticClass: "bar",
    style: {
      width: _vm.progressList[3].progress + 'px'
    }
  })])])]), _c('div', {
    staticClass: "btn",
    class: _vm.isContinue && 'active',
    on: {
      "click": function ($event) {
        _vm.isContinue && _vm.onContinue();
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("Continue")))])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };