var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "home",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "home-main"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('p', [_vm._v(_vm._s(_vm.$lang("Invite friends")))]), _c('p', [_vm._v(_vm._s(_vm.$lang("and get more ")) + _vm._s(_vm.setting.coin))])]), _c('div', {
    staticClass: "car"
  }, [_c('div', {
    staticClass: "car-main",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/friend-logo.png`)})`
    }
  }), _c('div', {
    staticClass: "car-invite"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Invited:")) + " "), _c('span', [_vm._v(_vm._s(_vm._f("toThousands")(_vm.userData.ref_count)))])])]), _c('div', {
    staticClass: "info"
  }, [_vm._v(_vm._s(_vm.$lang("Tap on the button to invite your friends")))]), _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.onShare();
      }
    }
  }, [_c('span', {
    style: {
      marginTop: _vm.theme == 'human' ? _vm.remTran(6) : _vm.remTran(0)
    }
  }, [_vm._v(_vm._s(_vm.$lang("Invite friends")))])]), _c('div', {
    staticClass: "btn-copy",
    on: {
      "click": function ($event) {
        return _vm.onCopy();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/btn-copy.png`),
      "alt": ""
    }
  })])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };